import React, { useEffect, useState } from "react";
import { RiEqualizerFill } from "react-icons/ri";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {
    getProductEspecificationsBrands,
    getProductEspecificationsModel,
    getProductEspecificationsYears
} from "../../../services/Product";
import OrangeDropdown from '../../../components/OrangeDropdown';

const Filters = ({ enableFilters, enableActions, enableWarehouse, warehousesOptions, statusOptions, onFilters, setShowFilters, showFilters }) => {
    const navigate = useNavigate();

    const actionsOptions = [
        { label: 'Transfer', path: '/inventory/transfer' },
        { label: 'Stocktake', path: '/inventory/stocktake' },
        { label: 'Add', path: '/inventory/element' },
    ];

    //const [showFilters, setShowFilters] = useState(true);
    const [openDropDown, setOpenDropDown] = useState(false);
    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false },
        year: { placeholder: "Year", status: false },
        brand: { placeholder: "Make", status: false },
        model: { placeholder: "Model", status: false },
        warehouse: { placeholder: "Warehouse", status: false },
        nag: { placeholder: "Nags", status: false },
        status: { placeholder: "Type", status: false }
    });
    const [yearsOptions, setYearsOptions] = useState([]);
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [options, setOptions] = useState({
        search: "",
        year: "",
        brand: "",
        model: "",
        warehouse: "",
        nag: "",
        status: ""
    });

    const getYears = () => {
        setMakeOptions([]);
        setModelOptions([]);
        getProductEspecificationsYears().then(res => {
            if (res.status === 200) setYearsOptions(res.data);
        });
    }

    const getBrands = (yearParam) => {
        setModelOptions([]);
        getProductEspecificationsBrands(yearParam).then(res => {
            if (res.status === 200) setMakeOptions(res.data);
        });
    }

    const getModels = (brandParam) => {
        getProductEspecificationsModel(options.year, brandParam).then(res => {
            if (res.status === 200) setModelOptions(res.data);
        });
    }

    const handleChangeOpts = (evt) => {
        const { name, value } = evt.target;
        switch (name) {
            case "year":
                getBrands(value);
                setOptions({...options, year: value, brand: "", model: "", warehouse: ""});
                setFocusClass({...focusClass, brand: { placeholder: "Make", status: false }, model: { placeholder: "Model", status: false }, warehouse: { placeholder: "Warehouse", status: false }});
                break;
            case "brand":
                getModels(value);
                setOptions({...options, brand: value, model: "", warehouse: ""});
                setFocusClass({...focusClass, model: { placeholder: "Model", status: false }, warehouse: { placeholder: "Warehouse", status: false }});
                break;
            case "model":
                setOptions({...options, model: value, warehouse: ""});
                setFocusClass({...focusClass, warehouse: { placeholder: "Warehouse", status: false }});
                break;
            default:
                break;
        }
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setOptions({...options, [name]: value});
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const { search, nag, ...otherOptions } = options;
        const searchQuery = {
            search: search.trim(),
            nag: nag.trim(),
            ...otherOptions
        };
        onFilters(searchQuery);
    }

    useEffect(() => {
        getYears();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            { showFilters ? (
                <div className="grid gap-3 lg:gap-7 md:grid-cols-5">
                    <div className="col-span-5">
                        <input
                            className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                            type="text"
                            name="search"
                            placeholder={focusClass.search.placeholder}
                            onChange={handleChange}
                            disabled={!enableFilters}
                            onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                            onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                            maxLength={40}
                            value={options.search}
                        />
                    </div>

                    <div className="col-span-5 md:col-span-1 space-y-3 md:pr-3 lg:pr-8 md:border-r md:border-ag-secondary">
                        <div>
                            <button
                                className="w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl"
                                type="button"
                                disabled={!enableFilters}
                                onClick={() => setShowFilters(!showFilters)}
                            >
                                <span className="text-base lg:text-2xl">
                                    <RiEqualizerFill />
                                </span>

                                <span>Filters</span>
                            </button>
                        </div>

                        <OrangeDropdown options={actionsOptions} enableActions={enableActions} enableFilters={enableFilters} />
                    </div>

                    <div className="col-span-5 md:col-span-4 space-y-3">
                        <div className="grid gap-3 lg:gap-7 md:grid-cols-4">
                            <div>
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.year.status ? "text-left" : "text-center"}`}
                                    name="year"
                                    value={options.year}
                                    onChange={handleChangeOpts}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, year: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, year: { placeholder: "Year", status: false }}) : null}
                                >
                                    <option value="">Year</option>
                                    {yearsOptions.map((element, index) => (
                                        <option key={index} value={element.year}>{ element.year }</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-text-sky-50 truncate ${focusClass.brand.status ? "text-left" : "text-center"}`}
                                    name="brand"
                                    value={options.brand}
                                    onChange={handleChangeOpts}
                                    disabled={!enableFilters || !options.year}
                                    onFocus={() => setFocusClass({...focusClass, brand: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, brand: { placeholder: "Make", status: false }}) : null}
                                >
                                    <option value="">Make</option>
                                    {makeOptions.map((element, index) => (
                                        <option key={index} value={element.brand}>{ element.brand }</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-text-sky-50 truncate ${focusClass.model.status ? "text-left" : "text-center"}`}
                                    name="model"
                                    value={options.model}
                                    onChange={handleChangeOpts}
                                    disabled={!enableFilters || !options.brand}
                                    onFocus={() => setFocusClass({...focusClass, model: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, model: { placeholder: "Model", status: false }}) : null}
                                >
                                    <option value="">Model</option>
                                    {modelOptions.map((element, index) => (
                                        <option key={index} value={element.model}>{ element.model }</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-text-sky-50 truncate ${focusClass.warehouse.status ? "text-left" : "text-center"}`}
                                    name="warehouse"
                                    value={options.warehouse}
                                    onChange={handleChange}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, warehouse: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, warehouse: { placeholder: "Warehouse", status: false }}) : null}
                                >
                                    <option value="">Warehouse</option>
                                    {warehousesOptions.map((element, index) => (
                                        <option key={index} value={element._id}>{element.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="grid gap-3 lg:gap-7 md:grid-cols-5">
                            <div className="md:col-span-2">
                                <input
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.nag.status ? "text-left" : "text-center"}`}
                                    type="text"
                                    name="nag"
                                    placeholder={focusClass.nag.placeholder}
                                    onChange={handleChange}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, nag: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, nag: { placeholder: "Nags", status: false }}) : null}
                                    maxLength={20}
                                />
                            </div>

                            <div className="md:col-span-2">
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.status.status ? "text-left" : "text-center"}`}
                                    name="status"
                                    defaultValue="default"
                                    onChange={handleChange}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, status: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, status: { placeholder: "Status", status: false }}) : null}
                                >
                                    <option value="default">Status</option>
                                    {statusOptions.map((element, index) => (
                                        <option key={index} value={element.value}>{element.label}</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <button
                                    className="w-full bg-ag-secondary text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                    type="submit"
                                    disabled={!enableFilters}
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="grid gap-3 lg:gap-7 md:grid-cols-5 md:pb-3">
                    <div>
                        <button
                            className="w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
                            type="button"
                            disabled={!enableFilters}
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            <span className="text-base lg:text-2xl">
                                <RiEqualizerFill />
                            </span>

                            <span>Filters</span>
                        </button>
                    </div>

                    <OrangeDropdown options={actionsOptions} enableActions={enableActions} enableFilters={enableFilters} />

                    <div className="md:col-span-3"></div>
                </div>
            )}
        </form>
    );
}

export default Filters;
